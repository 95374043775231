<template>
  <div class="form">
    <transition-group name="flip-list" tag="div" class="column">
      <div v-for="(chapter, i) in sortedChapters" :key="chapter.id"
        class="column chapter"
      >
        <div class="row chapter-head">
          <div class="column chapter-index">
            <p>{{ i + 1 }}</p>
            <div class="chapter-order">
              <div v-if="i < editedChapters.length - 1"
                class="down"
                title="Déplacer vers le bas"
                @click="moveChapter(editedChapters, i, i + 1)"
              >
                <SVGArrow />
              </div>
              <div v-if="i > 0"
                class="up"
                title="Déplacer vers le haut"
                @click="moveChapter(editedChapters, i, i - 1)"
              >
                <SVGArrow />
              </div>
            </div>
          </div>

          <Input
            class="chapter-name"
            title="Nom du chapitre"
            v-model="chapter.title"
            @blur="saveName(chapter.id)"
          />

          <p>{{ chapter.guides.length }} {{ chapter.guides.length > 1 ? 'guides' : 'guide'}}</p>
          <div @click="deleteChapter(chapter.id)" class="delete icon"><TrashIcon /></div>

        </div>

        <transition-group name="flip-list" tag="div" class="column chapter-body">
          <div v-for="(guide, j) in chapter.guides" :key="guide.id"
            class="row guide"
          >
            <div class="column guide-index">
              <p>{{ j + 1 }}</p>
              <div class="guide-order">
                <div v-if="j < chapter.guides.length - 1"
                  class="down"
                  title="Déplacer vers le bas"
                  @click="moveGuide(chapter, j, j + 1, chapter)"
                >
                  <SVGArrow />
                </div>
                <div v-if="j > 0"
                  class="up"
                  title="Déplacer vers le haut"
                  @click="moveGuide(chapter, j, j - 1)"
                >
                  <SVGArrow />
                </div>
              </div>
            </div>

            <div class="column guide-content">
              <Input
                type="text"
                title="Nom du guide"
                v-model="guide.title"
                @blur="editGuide(chapter.id,guide.id,'title')"
              />
              <Textarea
                title="Description"
                v-model="guide.description"
                @blur="editGuide(chapter.id,guide.id,'description')"
              />

              <Select
                title="Type de compte"
                :options="accountTypes"
                v-model="guide.accountType"
                @blur="editGuide(chapter.id,guide.id,'accountType')"
              />
            </div>
            <InputFile
              title="Fichier PDF"
              :preview="guide.pdfDocUrl"
              v-model="guide.pdfDocId"
              type="document"
              @uploaded="editGuide(chapter.id, guide.id, 'pdfDocId')"
            />
            <InputFile
              type="image"
              title="Couverture du guide"
              :preview="guide.coverUrl"
              v-model="guide.coverId"
              @uploaded="editGuide(chapter.id, guide.id, 'coverId')"
            />
            <div @click="deleteGuide(chapter.id, guide.id)" class="delete icon"><TrashIcon /></div>

          </div>
        </transition-group>

        <div class="buttons-container">
          <Button
            type="primary"
            title="Ajouter un guide"
            @click="addGuide(chapter.id)"
          >
            <SVGPlus />
          </Button>
        </div>
      </div>
    </transition-group>

    <div class="buttons-container">
      <Button
        type="primary"
        title="Ajouter un chapitre"
        @click="addChapter()"
      >
        <SVGPlus />
      </Button>
    </div>
  </div>
</template>

<script>
import Input from '@/components/Forms/Fields/Input'
import InputFile from '@/components/Forms/Fields/InputFile'
import Select from '@/components/Forms/Fields/Select'
import Textarea from '@/components/Forms/Fields/Textarea'
import Button from '@/components/Button'
import SVGArrow from '@/assets/icons/arrow.svg'
import SVGPlus from '@/assets/icons/plus.svg'
import TrashIcon from '@/assets/icons/trash.svg'

export default {
  name: 'GuidesForm',
  components: {
    Input, InputFile, Select, Textarea, Button, SVGArrow, SVGPlus,TrashIcon
  },
  props: ['chapters'],
  data () {
    return {
      editedChapters: [],
      accountTypes: [ {name:'Patient',value:1}, {name:'Rééducateur',value:2}, {name:'Responsable de structure',value:3} ]
    }
  },
  mounted () {
    this.editedChapters = this.chapters
  },
  methods: {
    moveGuide(chapter, from, to){
      const f = chapter.guides.splice(from, 1)[0]
      chapter.guides.splice(to, 0, f);
      this.$store.dispatch('guides/orderGuides', {chapterId:chapter.id,guidesId: chapter.guides.map(g=>g.id)})
    },
    async addChapter(){
      const chapter = await this.$store.dispatch('guides/createChapter')
      this.editedChapters.push(chapter)
    },
    async saveName(chapterId) {
      const {title} = this.editedChapters.find(c=>c.id===chapterId)
      await this.$store.dispatch('guides/editChapter', {chapterId,title})
    },
    async moveChapter(chapters, from, to){
      const f = chapters.splice(from, 1)[0]
      chapters.splice(to, 0, f);
      await this.$store.dispatch('guides/orderChapters', {chaptersId: chapters.map(c=>c.id)})
    },
    deleteChapter(chapterId){
      this.$store.dispatch('guides/deleteChapter', {chapterId})
      const index = this.editedChapters.findIndex(c=>c.id===chapterId)
      this.editedChapters.splice(index, 1);
    },
    async addGuide(chapterId){
      const guide = await this.$store.dispatch('guides/createGuide', {chapterId})
      this.editedChapters.find(c=>c.id===chapterId).guides.push(guide)
    },
    async editGuide(chapterId,guideId,field) {
      const guide = this.editedChapters.find(c=>c.id===chapterId).guides.find(g=>g.id===guideId)
      const fields = {}
      fields[field] = guide[field]
      await this.$store.dispatch('guides/editGuide', {chapterId,guideId,fields})
    },
    async deleteGuide(chapterId,guideId) {
      await this.$store.dispatch('guides/deleteGuide', {chapterId,guideId})
      const chapter = this.editedChapters.find(c=>c.id===chapterId)
      const index = chapter.guides.findIndex(g=>g.id===guideId)
      chapter.guides.splice(index, 1);
    }
  },
  computed: {
    sortedChapters: {
      get () {
        return this.editedChapters
      },
      set (newValue) {
        this.editedChapters = newValue
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.flip-list-move {
  transition: transform 0.5s;
}

.chapter{
  border-bottom: 10px solid $light-grey-color;
  margin-bottom: 20px;

  &:last-child{
    border: none;
  }

  .chapter-head{
    font-size: 1.25rem;
    font-weight: 420;

    & > p{
      padding: 15px 10px;
    }

    .chapter-name{
      flex: 1;
    }

    .chapter-index{
      padding: 15px 10px;

      .chapter-order{
        display: flex;
        width: 35px;
        padding-top: 10px;

        .up, .down{
          width: 15px;
          height: 23px;
          cursor: pointer;
        }

        .down{
          margin-right: 5px;
        }

        .up{
          transform: rotate(180deg);
        }
      }
    }
  }

  .chapter-body{
    padding-left: 55px;

    .guide{
      border-bottom: 5px solid $light-grey-color;
      background: #fff;

      &:first-child{
        border-top: 5px solid $light-grey-color;
      }

      .guide-index{
        padding: 15px 10px;
        font-size: 1.25rem;
        font-weight: 420;

        .guide-order{
          display: flex;
          width: 35px;
          padding-top: 10px;

          .up, .down{
            width: 15px;
            height: 23px;
            cursor: pointer;
            transition: all 0.25s;

            &:hover{
              color: $main-color;
            }
          }

          .down{
            margin-right: 5px;
          }

          .up{
            transform: rotate(180deg);
          }
        }
      }

      .guide-content{
        flex: 1;
      }
    }
  }
}
.delete.icon {
  margin-top: 10px;
  height: 16px;
  width: 16px;
  cursor: pointer;
  &:hover {
    margin-top: 9px;
    height: 18px;
    width: 18px;
  }
}

.buttons-container{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}
</style>
