<template>
  <div class="field">
    <label :for="name">{{ title }}</label>
    <textarea
      :id="name"
      :value="value"
      @input="$emit('input', $event.target.value)"
      rows="5"
      @blur="$emit('blur')"
    >
    </textarea>
  </div>
</template>

<script>
export default {
  name: 'Textarea',
  props: {
    title: String,
    name: String,
    value: String
  }
}
</script>

<style lang="scss" scoped>
.field{
  display: flex;
  flex-direction: column;
  padding: 15px 10px;

  label{
    font-size: 1.2rem;
    font-weight: 420;
    margin-bottom: 10px;
  }

  textarea{
    padding: 10px 10px;
    font-size: 1.05rem;
    resize: none;
    background: $light-background-color;
    -webkit-appearance: none;
    border: 1px solid $light-background-color;
    border-radius: 10px;
    transition: all 0.25s;

    &:focus{
      outline: none;
      border: 1px solid $light-text-color;
    }

    &:hover{
      border: 1px solid $light-text-color;
    }
  }
}
</style>