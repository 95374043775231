<template>
  <div class="field">
    <label>{{ title }}</label>

    <div class="field-content" :class="{ vertical: type !== 'audio' }">
      <div v-if="type === 'image'" class="preview image">
        <img v-if="previewUrl" :src="previewUrl" alt="preview" />

        <div class="progress" :class="{ uploading }">
          <div class="bar" :style="{ width: uploadPercent + '%' }"></div>
        </div>
      </div>

      <div v-if="type === 'video'" class="preview video">
        <video
          oncontextmenu="return false;"
          controlsList="nodownload"
          :src="previewUrl"
          type="video/mp4"
          controls
          v-if="previewUrl"
          ref="video"
          @loadedmetadata="durationVideo"
        ></video>

        <div class="progress" :class="{ uploading }">
          <div class="bar" :style="{ width: uploadPercent + '%' }"></div>
        </div>
      </div>

      <div v-if="type === 'audio'" class="preview audio">
        <audio controls :src="previewUrl"></audio>

        <div class="progress" :class="{ uploading }">
          <div class="bar" :style="{ width: uploadPercent + '%' }"></div>
        </div>
      </div>
      <div v-if="type === 'document'" class="preview audio">
        <iframe
          controlsList="nodownload"
          oncontextmenu="return false;"
          :src="previewUrl"
          title="previewUrl"
        ></iframe>

        <div class="progress" :class="{ uploading }">
          <div class="bar" :style="{ width: uploadPercent + '%' }"></div>
        </div>
      </div>

      <input ref="fileInput" type="file" :accept="`${type}/*`" @change="uploadFile" />

      <Button :title="buttonTxt" type="secondary" @click="$refs.fileInput.click()" />
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button"
export default {
  name: "InputFile",
  components: {
    Button
  },
  props: {
    title: String,
    name: String,
    type: {
      validator: function (value) {
        return ["image", "video", "audio", "document"].indexOf(value) !== -1
      }
    },
    typeFile: {
      default: ""
    },
    preview: String,
    value: String,
    onUploadStarts: {
      type: Function,
      default: () => {}
    },
    onUploadEnds: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      uploadedFile: null,
      uploadPercent: 0,
      uploading: false,
      types: {
        image: "Thumbnail",
        video: "Video",
        audio: "Audio",
        document: "Document"
      }
    }
  },
  methods: {
    durationVideo() {
      this.$emit("getDurationVideo", this.$refs?.video?.duration)
    },
    async uploadFile(e) {
      if (e.target.files.length === 0) return

      const file = e.target.files[0]
      const type = this.types[this.type]

      this.uploadPercent = 0
      this.uploading = true
      this.onUploadStarts()

      try {
        const uploadedFile = await this.$store.dispatch("admin/addFile", {
          type,
          file,
          onUploadProgress: this.onUploadProgress
        })
        this.uploadedFile = uploadedFile
        this.$emit("input", this.uploadedFile.id)
        this.previewUrl = this.uploadedFile.url
        this.$emit("changeUrl", {
          url: this.uploadedFile.url,
          id: this.uploadedFile.id,
          type: this.typeFile
        })
        this.$emit("uploaded")
      } catch (e) {
        console.error(e)
      }

      this.uploading = false
      this.onUploadEnds()
    },
    onUploadProgress(progressEvent) {
      this.uploadPercent = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      this.$emit("update", this.uploadPercent)
    }
  },
  computed: {
    previewUrl: {
      get() {
        return !this.uploadedFile ? this.preview : this.uploadedFile.url
      },
      set(newValue) {
        return newValue
      }
    },
    buttonTxt() {
      if (this.type === "image") return "Choisir une image"
      else if (this.type === "video") return "Choisir une vidéo"
      else if (this.type === "document") return "Choisir un document"
      else if (this.type === "audio") return "Choisir un son"
      else return "Choisir un fichier"
    }
  }
}
</script>

<style lang="scss" scoped>
.field {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 15px 10px;

  label {
    font-size: 1.2rem;
    font-weight: 420;
    margin-bottom: 10px;
  }

  .field-content {
    display: flex;
    flex-direction: row;
    align-items: center;

    .preview {
      width: 100%;
      overflow: hidden;
      margin-right: 25px;
      min-height: 128px;

      &.image,
      &.video {
        width: 100%;
        position: relative;
        padding-top: 56%;
        background: $light-grey-color;
        border-radius: 10px;
        overflow: hidden;

        img,
        video {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          object-fit: cover;
        }
      }

      &.audio {
        position: relative;
        width: 100%;
        height: 54px;
        background: $light-grey-color;
        border-radius: 10px;
      }

      .progress {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 15px;
        opacity: 0;
        transition: all 0.25s;

        &.uploading {
          opacity: 1;
        }

        .bar {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          background: $main-color;
          transition: all 0.25s;
        }
      }

      img,
      video,
      audio {
        width: 100%;
        border-radius: 10px;

        &:focus {
          outline: none;
        }
      }
    }

    input {
      position: absolute;
      width: 1px;
      height: 1px;
      z-index: -5;
      padding: 10px 10px;
      font-size: 1rem;
    }

    &.vertical {
      flex-direction: column;

      .preview {
        margin: 0;
        margin-bottom: 10px;
      }
    }
  }
}
</style>
