<template>
  <div class="field" :class="containerClass">
    <label :for="name" :class="labelClass">{{ title }}</label>

    <div class="select-wrapper">
      <select
        :id="name"
        :value="valueChanged"
        @input="$emit('input', isNumber($event.target.value))"
        @blur="$emit('blur')"
      >
        <option v-for="(option, i) in options" :key="i" :value="option.value">
          {{ option.name }}
        </option>
      </select>
      <div class="icon">
        <SVGArrow />
      </div>
    </div>
  </div>
</template>

<script>
import SVGArrow from "@/assets/icons/arrow.svg"

export default {
  name: "Select",
  components: {
    SVGArrow
  },
  data() {
    return {
      valueChanged: ""
    }
  },
  props: {
    containerClass: {
      default: "",
      type: String
    },
    labelClass: {
      default: "",
      type: String
    },
    title: String,
    name: String,
    value: {
      type: [Number, Object, String]
    },
    selectedValue: {
      type: String
    },
    options: Array
  },
  created() {
    if (this.selectedValue) {
      this.valueChanged = this.selectedValue
    } else {
      this.valueChanged = this.value
    }
  },
  methods: {
    isNumber(e) {
      return isNaN(Number(e)) ? e : Number(e)
    }
  }
}
</script>

<style lang="scss" scoped>
.field {
  display: flex;
  flex-direction: column;
  padding: 15px 10px;

  label {
    font-size: 1.2rem;
    font-weight: 420;
    margin-bottom: 10px;
  }

  .select-wrapper {
    display: flex;
    position: relative;

    select {
      flex: 1;
      padding: 10px 10px;
      font-size: 1.05rem;
      background: $light-background-color;
      -webkit-appearance: none;
      border: 1px solid $light-background-color;
      border-radius: 10px;
      transition: all 0.25s;

      &:focus {
        outline: none;
        border: 1px solid $light-text-color;
      }

      &:hover {
        border: 1px solid $light-text-color;
      }
    }

    .icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
      width: 14px;
    }
  }
}
</style>
