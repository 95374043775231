<template>
  <AdminView>
    <h1 class="title-1">Guides</h1>
    <GuidesForm :chapters="chapters" />
  </AdminView>
</template>

<script>
import AdminView from '@/components/AdminView'
import GuidesForm from '@/components/Forms/GuidesForm'

export default {
  name: 'GuidesList',
  components: {
    AdminView, GuidesForm
  },
  data () {
    return {
      chapters: [ ]
    }
  },
  async created(){
    const chapters = await this.$store.dispatch('guides/getAll')
    this.chapters.push(...chapters)
  }
}
</script>

<style lang="scss" scoped>

</style>
